import { type ComponentProps } from "react";

export const SliderArrowLeft = (props: ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={32}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.543 2.698a1.597 1.597 0 0 0 .442-1.144 1.585 1.585 0 0 0-.506-1.117A1.621 1.621 0 0 0 15.322 0a1.634 1.634 0 0 0-1.13.5L.441 14.893a1.59 1.59 0 0 0 0 2.197l13.75 14.393a1.617 1.617 0 0 0 1.133.516 1.634 1.634 0 0 0 1.168-.435A1.6 1.6 0 0 0 17 30.436a1.584 1.584 0 0 0-.457-1.149l-12.7-13.296 12.7-13.293Z"
      />
    </svg>
  );
};
