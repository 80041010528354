import { type ComponentProps } from "react";

export const SliderArrowRight = (props: ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={32}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.457 2.698a1.597 1.597 0 0 1-.441-1.144A1.584 1.584 0 0 1 .522.437 1.62 1.62 0 0 1 1.679 0a1.634 1.634 0 0 1 1.13.5l13.749 14.392a1.59 1.59 0 0 1 0 2.197L2.808 31.483a1.617 1.617 0 0 1-1.133.516 1.634 1.634 0 0 1-1.168-.435A1.6 1.6 0 0 1 0 30.436a1.583 1.583 0 0 1 .457-1.149l12.7-13.296L.458 2.698Z"
      />
    </svg>
  );
};
