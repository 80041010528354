import { type ComponentProps } from "react";

export const ArrowBottom = (props: ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m4.5 8.75 7.5 7.5 7.5-7.5"
      />
    </svg>
  );
};
